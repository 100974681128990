import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import LoadingSpinner from 'src/Components/Shared/LoadingSpinner';

const PDF = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const { id } = useParams(); // Extracting the receipt ID from the URL

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDecodeURL(id); // Call API when id changes or on component mount
    }, 300); // Adjust debounce time as needed (300ms here)

    return () => clearTimeout(timeoutId); // Clean up on unmount or id change
  }, [id]); // Dependency array set to [id]

  const getDecodeURL = async (Id:any) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/quickbooks/pdf?receiptId=${Id}`);
      const { content } = response.data;
      const byteCharacters = atob(content); // Decode base64
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const pdfUrl:any = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  return (
    <div>
      {pdfUrl ? (
        <iframe
          src={pdfUrl}
          title="Receipt PDF"
          width="100%"
          height="600px"
          style={{ border: 'none' }}
        ></iframe>
      ) : (
        <p style={{width: "100%" }}><LoadingSpinner /></p>
      )}
    </div>
  );
};

export default PDF;
