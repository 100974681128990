import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { TabsWraper, TabsHeader } from './styles'; // Import any additional styles needed
import SubHeading from '../SubHeading';
import HeroButton from '../HeroButton';
import SearchForm from '../SearchForm';
import { data } from 'src/Assets/Content/data';
import JobApplication from 'src/Pages/Job-application';
import JobOrders from 'src/Pages/Job-Orders';
import Customers from 'src/Pages/Customers';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/Components/Constant/routes';
import { useDispatch } from 'react-redux';
import { JobsAction } from 'src/Redux/App/Actions/Jobs';
import { CustomerAction } from 'src/Redux/App/Actions/Auth';

const CustomTabs: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const record = location.state && location.state;
  const [activeTabKey, setActiveTabKey] = useState(
    localStorage.getItem('key') ? localStorage.getItem('key') : record?.tab
  );
  //  function to get title from data file
  const getTitle = (key: string) => {
    for (const item of data) {
      const foundItem = item.tableData.find(
        (tableItem) => tableItem.key === key
      );
      if (foundItem) {
        return item.title;
      }
    }
    return '';
  };
 

  useEffect(() => {
    localStorage.removeItem('pakageId')
    localStorage.removeItem('add')
    
    if (record?.tab && localStorage.getItem('key')) {
      setActiveTabKey(localStorage.getItem('key') ? localStorage.getItem('key') : record?.tab);
    }
  }, [1000]);

  // Custom click handler to override the default behavior
  const handleCustomTabClick = (key: any) => {
    setActiveTabKey(key);
    localStorage.setItem('key', key);
    if (key == '1' || key == '2') {
      dispatch<any>(JobsAction());
    }
    if (key == '3') {
      dispatch<any>(CustomerAction());
    }
  };

  return (
    <TabsWraper>
      <Row>
        <Col span={24}>
          <TabsHeader>
            <Row>
              <Col span={7}>
                <SubHeading>{getTitle(activeTabKey)}</SubHeading>
              </Col>
              <Col span={10}>
                <Tabs
                  defaultActiveKey={activeTabKey}
                  onTabClick={handleCustomTabClick}
                >
                  <TabPane
                    tab='Job Applications'
                    key='1'
                  />
                  <TabPane
                    tab='Job Orders'
                    key='2'
                  />
                  <TabPane
                    tab='Customers'
                    key='3'
                  />
                </Tabs>
              </Col>
              <Col span={7}>
                {activeTabKey === '1' && (
                  <Row>
                    <Col span={7}></Col>
                    <Col span={10}></Col>
                    <Col span={7}>
                      <span
                        onClick={() => {
                          const record = {};
                          navigate(AppRoutes.application, {
                            state: { record },
                          });
                          localStorage.setItem('add', 'Add-Application');
                        }}
                      >
                        <HeroButton>ADD APPLICATION</HeroButton>
                      </span>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </TabsHeader>
        </Col>
      </Row>
      
      <div className='tab-content'>
        {activeTabKey === '1' && <JobApplication />}
        {activeTabKey === '2' && <JobOrders />}
        {activeTabKey === '3' && <Customers />}
      </div>
    </TabsWraper>
  );
};

export default CustomTabs;
