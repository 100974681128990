import { Button, Col, Form, Input, Row } from 'antd';
import { columns } from 'src/Assets/Content/Customers/data';
import SearchIcon from 'src/Assets/Icons/SearchIcon';
import { SearchFormWraper, TableWraper } from 'src/Styles/global';
import { Pagination, Table } from 'antd';
import { useEffect, useState } from 'react';
import Previous from 'src/Assets/Icons/Previous';
import Next from 'src/Assets/Icons/Next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CustomerAction,
  SortCustomerAction,
  SearchCustomerAction,
} from 'src/Redux/App/Actions/Auth';
import { useSelector } from 'react-redux';
import { data, loading } from '../../Redux/App/index';
import { AppRoutes } from 'src/Components/Constant/routes';

const Customers = () => {
  const users: any = useSelector(data);
  const loadingState = useSelector(loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25); // Default page size
  const [keyword, setKeword] = useState('');

  const getRowClassName = (record: any, index: number) => {
    return index % 2 === 0
      ? 'table-row-White-customer'
      : 'table-row-lite-customer';
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) setPageSize(pageSize);
  };

  const handleSizeChange = (current: number, size: number) => {
    setCurrentPage(1);
    setPageSize(size);
  };

  const searchUser = () => {
    dispatch<any>(SearchCustomerAction(keyword));
  };
  useEffect(() => {
    dispatch<any>(CustomerAction());
  }, [1000]);

  const totalItems = users.length;
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);
  let dynamicPageSizeOptions = ['10', '50', '100'];

  useEffect(() => {
    console.log(users);
  }, [users]);
  const handleColumnHeaderClick = (key: any, order: any) => {
    console.log(key, order);
    dispatch<any>(SortCustomerAction({ key, order, keyword }));
  };
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.order === undefined) {
      handleColumnHeaderClick('registerDate', 'DESC'); // Pass the column key and sort order to your click handler function
    } else {
      if (sorter && sorter.columnKey) {
        handleColumnHeaderClick(sorter.columnKey, sorter.order); // Pass the column key and sort order to your click handler function
      }
    }
  };

  const handleRowClick = (record: any) => {
    navigate(AppRoutes.customer, { state: { record } });
  };
  return (
    <>
      <SearchFormWraper>
        <Form
          layout='horizontal'
          // onFinishFailed={onFinishFailed}
          // onFinish={onFinish}
        >
          <Row
            style={{ marginBottom: '12px' }}
            justify={'end'}
          >
            <Col
              md={7}
              style={{ display: 'flex' }}
            >
              <Input
                type='search'
                placeholder='Search...'
                prefix={<SearchIcon />}
                style={{ marginRight: 8 }}
                onChange={(e) => setKeword(e.target.value)}
              />
              <Button
                onClick={searchUser}
                className='search-butn'
                htmlType='submit'
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </SearchFormWraper>
      <TableWraper>
        <Table
          dataSource={currentItems}
          columns={columns}
          rowClassName={getRowClassName}
          pagination={false}
          loading={loadingState}
          onChange={handleTableChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleRowClick(record),
            };
          }}
        />
        <div>
          {totalItems > 10 ? (
            <Pagination
              pageSize={pageSize}
              total={totalItems}
              current={currentPage}
              showSizeChanger={true}
              showTotal={(total) => `Result ${total} out of ${pageSize}`}
              onChange={handlePageChange}
              onShowSizeChange={handleSizeChange}
              prevIcon={<Previous disabled={currentPage === 1} />}
              nextIcon={<Next disabled={indexOfLastItem >= totalItems} />}
              pageSizeOptions={dynamicPageSizeOptions}
              itemRender={(page, type, originalElement) => {
                if (type === 'page') {
                  return <span>{page}</span>;
                }
                return originalElement;
              }}
            />
          ) : null}
        </div>
      </TableWraper>
    </>
  );
};

export default Customers;
